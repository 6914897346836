import React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'

import { isType } from '../../utils/typecheck'
import Arrow from '../../images/arrow.inline.svg'
import { shorten } from '../../utils/shorten'
import string_to_slug from '../../utils/slug'

export default function LinkImageGroup({ slug, slugIndex }) {
    return (
        <StaticQuery
            query={graphql`
                query projects {
                    allAirtable(
                        filter: { table: { eq: "Projekte" } }
                        sort: { order: ASC, fields: rowIndex }
                    ) {
                        group(field: data___Slug) {
                            nodes {
                                table
                                data {
                                    Type
                                    Content
                                    Attachments {
                                        src
                                        srcSet
                                        sizes
                                    }
                                    Slug
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) =>
                data.allAirtable.group.map((item, index) => {
                    if (item.nodes[0].data.Slug[0] === string_to_slug(slug)) {
                        let projectItem: any = {}
                        projectItem.link = `/${item.nodes[0].table.toLowerCase()}/${
                            item.nodes[0].data.Slug
                        }`
                        item.nodes.map((textItem) => {
                            switch (true) {
                                case isType(textItem.data.Type, 'H1'):
                                    projectItem.title = textItem.data.Content
                                case isType(textItem.data.Type, 'Teasertext'):
                                    projectItem.text = textItem.data.Content
                                case isType(textItem.data.Type, 'Titelbild'):
                                    projectItem.image = {
                                        srcSet: textItem.data.Attachments?.[0]
                                            .srcSet,
                                        sizes: textItem.data.Attachments?.[0]
                                            .sizes,
                                    }
                            }
                        })

                        switch (slugIndex) {
                            case 0:
                                return (
                                    <LinkImage
                                        key={index}
                                        href={projectItem.link}
                                        className="single-image"
                                    >
                                        <figure>
                                            {!!projectItem.image && (
                                                <img
                                                    alt=""
                                                    srcSet={
                                                        projectItem.image.srcSet
                                                    }
                                                    sizes={
                                                        projectItem.image.sizes
                                                    }
                                                />
                                            )}
                                            <figcaption>
                                                <div>
                                                    <h3 className="h4">
                                                        {projectItem.title}
                                                    </h3>
                                                    <button className="button arrow right color-white">
                                                        <Arrow />
                                                    </button>
                                                </div>
                                                <p>
                                                    {shorten(
                                                        projectItem.text,
                                                        120
                                                    )}{' '}
                                                    ...
                                                </p>
                                            </figcaption>
                                        </figure>
                                    </LinkImage>
                                )

                            case 1:
                                return (
                                    <LinkImage
                                        key={index}
                                        href={projectItem.link}
                                        className="image-2"
                                    >
                                        <figure>
                                            {!!projectItem.image && (
                                                <img
                                                    alt=""
                                                    srcSet={
                                                        projectItem.image.srcSet
                                                    }
                                                    sizes={
                                                        projectItem.image.sizes
                                                    }
                                                />
                                            )}
                                            <figcaption>
                                                <div>
                                                    <h3 className="h4">
                                                        {projectItem.title}
                                                    </h3>
                                                    <button className="button arrow right color-white">
                                                        <Arrow />
                                                    </button>
                                                </div>
                                                <p>
                                                    {shorten(
                                                        projectItem.text,
                                                        120
                                                    )}{' '}
                                                    ...
                                                </p>
                                            </figcaption>
                                        </figure>
                                    </LinkImage>
                                )

                            case 2:
                                return (
                                    <LinkImage
                                        key={index}
                                        href={projectItem.link}
                                        className="image-3"
                                    >
                                        <figure>
                                            {!!projectItem.image && (
                                                <img
                                                    alt=""
                                                    srcSet={
                                                        projectItem.image.srcSet
                                                    }
                                                    sizes={
                                                        projectItem.image.sizes
                                                    }
                                                />
                                            )}
                                            <figcaption>
                                                <div>
                                                    <h3 className="h4">
                                                        {projectItem.title}
                                                    </h3>
                                                    <button className="button arrow right color-white">
                                                        <Arrow />
                                                    </button>
                                                </div>
                                                <p>
                                                    {shorten(
                                                        projectItem.text,
                                                        120
                                                    )}{' '}
                                                    ...
                                                </p>
                                            </figcaption>
                                        </figure>
                                    </LinkImage>
                                )
                        }
                    }
                })
            }
        />
    )
}

const LinkImage = styled.a`
    transition: opacity 0.4s ease-in;

    &:hover {
        opacity: 0.75;
        text-decoration: none;
    }

    figure {
        figcaption {
            div {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }

            p {
                font-family: 'Abstract';
                font-weight: 300;
                max-width: 35rem;
            }
        }
    }

    &.image-2,
    &.image-3 {
        margin: 0 0 2rem 0;

        @media (min-width: ${(props) => props.theme.md}) {
            margin: 0;
        }
    }

    &.image-2 {
        grid-column: 2 / -3;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 2 / -13;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 3 / -13;
        }

        p {
            max-width: 25rem;
        }
    }

    &.image-3 {
        grid-column: 5 / -2;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 16 / -2;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 16 / -3;
        }

        p {
            max-width: 25rem;
        }
    }
`
