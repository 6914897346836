import * as React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import LinkImageGroup from '../components/sections/linkImageGroup'
import NextArticles from '../components/sections/nextArticles'
import WeAreHiring from '../components/sections/weAreHiring'
import Arrow from '../images/arrow.inline.svg'
import LogoCarousel from '../components/sections/logoCarousel'
import TextImage from '../components/sections/textImage'
import { isType } from '../utils/typecheck'
import Markdown from '../utils/markdown'
import WideArrowLink from '../components/simple/wideArrowLink'
import Contact from '../components/sections/contact'
import { WebVideo } from '../components/webVideo'

export const query = graphql`
    query GetIndex {
        allAirtable(
            filter: { table: { eq: "Startseite" } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Modul
                    Type
                    Content
                    Attachments {
                        src
                        srcSet
                        sizes
                    }
                    Vimeo_ID
                    YouTube_ID
                    Projekt_Slugs
                    Job_Slugs
                }
            }
        }
    }
`

export default function IndexPage({ data }) {
    const items = data.allAirtable.nodes

    let headerElements = []
    let missionElements = []
    let projectsElements = []
    let magazineElements = []
    let faibleElements = []
    let careerElements = []
    let careerInverted = false

    items.map((item) => {
        switch (item.data.Modul) {
            case 'Header':
                headerElements.push(item)
                break

            case 'Mission':
                missionElements.push(item)
                break

            case 'Projekte':
                projectsElements.push(item)
                break

            case 'Magazin':
                magazineElements.push(item)
                break

            case 'Faible':
                faibleElements.push(item)
                break

            case 'Karriere':
                careerElements.push(item)
                break

            case 'Karriere Invertiert':
                careerElements.push(item)
                careerInverted = true
                break
        }
    })

    const headerSection = headerElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H1'):
                return (
                    <React.Fragment key={index}>
                        <h1>{formattedContent}</h1>
                        <button
                            className="button arrow down color-white"
                            onClick={() =>
                                document
                                    .querySelector('.mission')
                                    ?.scrollIntoView()
                            }
                        >
                            <Arrow />
                        </button>
                    </React.Fragment>
                )

            case isType(item.data.Type, 'Titelbild') &&
                !isType(item.data.Type, 'Video'):
                return (
                    <figure key={index} className="cover-picture">
                        <img
                            alt=""
                            srcSet={item.data.Attachments[0].srcSet}
                            sizes={item.data.Attachments[0].sizes}
                        />
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="cover-video">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.url}
                        />
                    </div>
                )

            case !isType(item.data.Type, 'Titelbild') &&
                isType(item.data.Type, 'Video'):
                return (
                    <div key={index} className="single-image">
                        <WebVideo
                            type={item.data.Vimeo_ID ? 'vimeo' : 'youtube'}
                            videoId={item.data.Vimeo_ID ?? item.data.YouTube_ID}
                            figcaption={formattedContent}
                            thumbnail={item.data.Attachments?.[0]?.url}
                        />
                    </div>
                )
        }
    })

    const missionSection = missionElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Tag'):
                return (
                    <div key={index} className="tags">
                        {formattedContent}
                    </div>
                )

            case isType(item.data.Type, 'Logo-Karussell'):
                return (
                    <LogoCarousel key={index} logos={item.data.Attachments} />
                )
        }
    })

    const projectsSection = projectsElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Projekte'):
                return item.data.Projekt_Slugs.map((slug, i) => (
                    <LinkImageGroup key={i} slug={slug} slugIndex={i} />
                ))
        }
    })

    const magazineSection = magazineElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'H2'):
                return (
                    <NextArticles
                        heading={item.data.Content}
                        articlesCount={6}
                        key={index}
                    />
                )
        }
    })

    const faibleSection = faibleElements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Bild'):
                return (
                    <figure key={index} className="single-image">
                        {!!item.data.Attachments && (
                            <img
                                alt=""
                                srcSet={item.data.Attachments[0].srcSet}
                                sizes={item.data.Attachments[0].sizes}
                            />
                        )}
                        <figcaption>{formattedContent}</figcaption>
                    </figure>
                )

            case isType(item.data.Type, 'Bild-Text'):
            case isType(item.data.Type, 'Text-Bild'):
                return (
                    <TextImage
                        key={index}
                        type={item.data.Type}
                        image={item.data.Attachments[0].url}
                        text={item.data.Content}
                    />
                )

            case isType(item.data.Type, 'Link'):
                return <WideArrowLink key={index} text={item.data.Content} />
        }
    })

    const careerSection = (
        <WeAreHiring elements={careerElements} inverted={careerInverted} />
    )

    // React.useEffect(() => {
    //     sal({
    //         threshold: 1,
    //         once: false
    //     })
    // }, [])

    return (
        <Container className="index">
            <Layout>
                {headerSection}

                <div className="mission">{missionSection}</div>

                <div className="margin-container">{projectsSection}</div>

                {magazineSection}

                <div className="margin-container">{faibleSection}</div>

                {careerSection}

                <Contact />
            </Layout>
        </Container>
    )
}

const Container = styled.div`
    color: ${(props) => props.theme.colorGray};

    h1 {
        font-size: clamp(2.375rem, calc(0.9392rem + 3.6486vw), 3.777rem);
        margin-bottom: 1rem;
        padding-top: 1rem;
        grid-column: 2 / -2;

        @media (min-width: ${(props) => props.theme.sm}) {
            grid-column: 2 / -5;
        }

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 2 / span 19;
            margin-bottom: 7.5rem;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 3 / span 19;
        }
    }

    .arrow.button.down {
        grid-column: 2 / span 2;
        margin-bottom: 0.3rem;
        margin-left: -1rem;
        width: 3rem;

        @media (min-width: ${(props) => props.theme.sm}) {
            justify-self: end;
            grid-column: -5 / span 2;
            margin-bottom: 1.8rem;
            align-self: end;
        }

        @media (min-width: ${(props) => props.theme.md}) {
            margin-bottom: 9rem;
            margin-right: -1rem;
        }
    }

    .cover-picture,
    .cover-video {
        margin-top: 1rem;
    }

    .mission {
        display: grid;
        grid-template-columns: repeat(25, 1fr);
        padding: 2.5rem 0;
        color: ${(props) => props.theme.colorPrimary};
        position: relative;
        max-width: 100vw;
        overflow-x: hidden;

        @media (min-width: 1422px) {
            overflow-x: visible;
        }

        @media (min-width: ${(props) => props.theme.md}) {
            padding: 5.5rem 0 8rem 0;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100vw;
            height: 100%;
            background-color: ${(props) => props.theme.colorGrayLight};
            z-index: -1;
            grid-column: 2 / 26;
        }

        h2 {
            @media (min-width: ${(props) => props.theme.md}) {
                grid-column: 5 / -5;
            }

            @media (min-width: ${(props) => props.theme.lg}) {
                grid-column: 6 / span 11;
            }
        }

        .tags {
            @media (min-width: ${(props) => props.theme.lg}) {
                padding-top: 3rem;
            }

            ul li {
                line-height: 1.48;
            }
        }
    }
`
