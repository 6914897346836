import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel'

export default function LogoCarousel({ logos = [] }) {
    const [visibleSlides, setVisibleSlides] = useState(3)

    function handleViewportChange() {
        switch (true) {
            case window.innerWidth < 520:
                setVisibleSlides(3)
                break

            case window.innerWidth >= 520 && window.innerWidth < 1000:
                setVisibleSlides(4)
                break

            case window.innerWidth >= 1000 && window.innerWidth < 1280:
                setVisibleSlides(5)
                break

            case window.innerWidth >= 1280:
                setVisibleSlides(7)
                break
        }
    }

    useEffect(() => {
        handleViewportChange()
    })

    if (typeof window !== 'undefined') {
        window.onresize = handleViewportChange
    }

    return (
        <LogosCarouselContainer className="logo-carousel">
            <CarouselProvider
                naturalSlideWidth={92}
                naturalSlideHeight={75}
                totalSlides={7}
                visibleSlides={visibleSlides}
                step={1}
                dragEnabled={true}
                isPlaying={true}
                interval={5000}
            >
                <Slider>
                    {logos.map((logo, index) => {
                        return (
                            <Slide index={index} key={index}>
                                <Image src={logo.url} hasMasterSpinner alt="" />
                            </Slide>
                        )
                    })}
                </Slider>
            </CarouselProvider>
        </LogosCarouselContainer>
    )
}

const LogosCarouselContainer = styled.div`
    .carousel__inner-slide {
        display: grid;
        align-content: center;
    }

    img {
        width: 7.5rem;
        height: auto;
    }
`
