import React from 'react'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'

import { isType } from '../../utils/typecheck'
import Markdown from '../../utils/markdown'

export default function Contact() {
    let kickerCounter = -1

    return (
        <ContactContainer className="contact">
            <StaticQuery
                query={graphql`
                    query {
                        allAirtable(
                            filter: { table: { eq: "Kontakt" } }
                            sort: { order: ASC, fields: rowIndex }
                        ) {
                            nodes {
                                data {
                                    Type
                                    Content
                                    Attachments {
                                        src
                                        srcSet
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) =>
                    data.allAirtable.nodes.map((item, index) => {
                        if (isType(item.data.Type, 'Kolumne')) kickerCounter++

                        if (kickerCounter === 1) {
                            let formattedContent = (
                                <Markdown
                                    content={item.data.Content}
                                    p={false}
                                />
                            )

                            switch (true) {
                                case isType(item.data.Type, 'H2'):
                                    return (
                                        <h2 className="h1" key={index}>
                                            {formattedContent}
                                        </h2>
                                    )

                                case isType(item.data.Type, 'Kolumne'):
                                    return (
                                        <span key={index} className="kicker">
                                            {formattedContent}
                                        </span>
                                    )

                                case isType(item.data.Type, 'Bild'):
                                    return (
                                        <figure
                                            key={index}
                                            className="person-image"
                                        >
                                            {!!item.data.Attachments && (
                                                <img
                                                    alt=""
                                                    srcSet={
                                                        item.data.Attachments[0]
                                                            .srcSet
                                                    }
                                                    sizes={
                                                        item.data.Attachments[0]
                                                            .sizes
                                                    }
                                                />
                                            )}
                                            <figcaption>
                                                {formattedContent}
                                            </figcaption>
                                        </figure>
                                    )

                                case isType(item.data.Type, 'Link'):
                                    return (
                                        <div
                                            key={index}
                                            className="person-link"
                                        >
                                            {formattedContent}
                                        </div>
                                    )
                            }
                        }
                    })
                }
            />
        </ContactContainer>
    )
}

const ContactContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    padding: 2.5rem 0;
    color: ${(props) => props.theme.colorPrimary};
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;

    @media (min-width: 1422px) {
        overflow-x: visible;
    }

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 5.5rem 0 8rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: ${(props) => props.theme.colorGrayLight};
        z-index: -1;
        grid-column: 2 / 26;
    }

    h2 {
        margin-bottom: 2rem;

        @media (min-width: ${(props) => props.theme.md}) {
            margin-bottom: 3rem;
        }
    }

    .person-image {
        grid-column: 3 / -2;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / span 18;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            &:nth-of-type(1) {
                grid-column: 6 / span 7;
            }

            &:nth-of-type(2) {
                grid-column: 15 / span 7;
                grid-row: 2;
            }
        }

        figcaption {
            font-size: 1rem;

            strong {
                font-size: 1.222rem;
            }
        }
    }

    .person-link {
        font-family: 'SawtonBauhaus';
        grid-column: 3 / span 18;
        margin-bottom: 1rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / span 18;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                grid-column: 6 / span 5;
            }

            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
                grid-column: 15 / span 5;
                grid-row: 3;
            }

            &:nth-of-type(5) {
                grid-row: 4;
            }

            &:nth-of-type(6) {
                grid-row: 5;
            }
        }

        a {
            color: ${(props) => props.theme.colorPrimary};

            &:not([href*='tel']) {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
`
