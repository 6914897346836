import React from 'react'
import styled from 'styled-components'

import Markdown from '../../utils/markdown'
import { isType } from '../../utils/typecheck'
import WideArrowLink from '../simple/wideArrowLink'
import JobLink from '../simple/jobLink'

export default function WeAreHiring({ elements = [], inverted = false }) {
    const content = elements.map((item, index) => {
        let formattedContent = (
            <Markdown content={item.data.Content} p={false} />
        )

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return (
                    <span key={index} className="kicker">
                        {formattedContent}
                    </span>
                )

            case isType(item.data.Type, 'H2'):
                return (
                    <h2 key={index} className="h1">
                        {formattedContent}
                    </h2>
                )

            case isType(item.data.Type, 'Stellen'):
                return item.data.Job_Slugs.map((slug, i) => (
                    <JobLink key={`${index}-${i}`} jobSlug={slug} />
                ))

            case isType(item.data.Type, 'Link'):
                return (
                    <WideArrowLink
                        key={index}
                        onWhiteBackground={!inverted}
                        text={item.data.Content}
                    />
                )
        }
    })

    if (inverted) {
        return (
            <WeAreHiringContainer className="we-are-hiring">
                {content}
            </WeAreHiringContainer>
        )
    } else {
        return (
            <WeAreHiringContainerWhite className="we-are-hiring">
                {content}
            </WeAreHiringContainerWhite>
        )
    }
}

const WeAreHiringContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    padding: 2.5rem 0;
    position: relative;
    max-width: 100vw;
    overflow-x: hidden;

    @media (min-width: 1422px) {
        overflow-x: visible;
    }

    @media (min-width: ${(props) => props.theme.md}) {
        padding: 5.5rem 0 8rem 0;
    }

    h2 {
        @media (min-width: ${(props) => props.theme.md}) {
            grid-column-end: -4;
        }
    }

    .job {
        grid-column: 3 / -2;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -4;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'SawtonBauhaus';
        padding: 0.5rem 0;
        color: ${(props) => props.theme.colorGray};
        border-bottom: 0.1rem solid ${(props) => props.theme.colorGray};

        &:first-of-type {
            margin-top: 1rem;
            border-top: 0.1rem solid ${(props) => props.theme.colorGray};

            @media (min-width: ${(props) => props.theme.md}) {
                margin-top: 3rem;
            }
        }

        .title {
            font-size: 1.222rem;
            margin-right: 1rem;
        }

        .link {
            display: flex;
            align-items: center;

            @media (min-width: ${(props) => props.theme.md}) {
                min-width: 10rem;
            }

            span {
                margin-right: 2rem;
                font-weight: 500;
                font-size: 0.888rem;
                display: none;

                @media (min-width: ${(props) => props.theme.md}) {
                    display: inline;
                }
            }
        }
    }
`

const WeAreHiringContainerWhite = styled(WeAreHiringContainer)`
    color: ${(props) => props.theme.colorPrimary};

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        background-color: ${(props) => props.theme.colorGrayLight};
        z-index: -1;
        grid-column: 2 / 26;
    }

    .job {
        color: ${(props) => props.theme.colorPrimary};
        border-bottom: 0.1rem solid ${(props) => props.theme.colorPrimary};

        &:first-of-type {
            border-top: 0.1rem solid ${(props) => props.theme.colorPrimary};
        }

        .link {
            .button {
                color: ${(props) => props.theme.colorPrimary};
            }
        }
    }
`
